import React from 'react'

import generatePcClassPageUrlById from '@/utils/generatePcClassPageUrlById'
import upLetter from '@/utils/upLetter'

import pcClassList from '@/constants/pcClassList'

import generatePcSubClassList from './utils/generatePcSubClassList'

import PcClassListComponent from './PcClassListComponent'

export default () => {
  const list = pcClassList.map(
    ({ id, name: { singular: { nominative } } }) => ({
      id,
      url: generatePcClassPageUrlById(id),
      title: upLetter(nominative),
      childList: generatePcSubClassList(id),
    }),
  )

  return (
    <PcClassListComponent list={list}/>
  )
}
