import React from 'react'

import SourceInfo from '@/components/SourceInfo'
import HeaderBlock from '@/components/HeaderBlock'
import Markdown from '@/components/Markdown'
import DescriptionList from "@/components/DescriptionList"
import Description from "@/components/Description"
import Note from '@/components/Note'
import PcClassAbilityList from '@/components/PcClassAbilityList'
import PcClassFeature from '@/components/PcClassFeature'

import generateTextLinks from '@/utils/generateTextLinks'
import proficiencyBonusTable from '@/constants/proficiencyBonusTable'
import PROGRESS_TABLE_ID from '@/constants/PROGRESS_TABLE_ID'
import SPELL_SLOT_TABLE_ID from '@/constants/SPELL_SLOT_TABLE_ID'

import PcClassImageList from './components/PcClassImageList'

import './PcClassStyles.less'

const PcClassComponent = (
  {
    header,
    descriptionMain,
    descriptionOtherList,
    featureList,
    note,
    pcClass,
    subHeader,
    pcClassId,
    pcClassText,
    progressTable,
    spellSlotTable,
    source,
    ...rest
  },
) => (
  <section className='PcClass'>
    <section className='PcClass_infoBlock'>
      <HeaderBlock
        className='PcClass_header'
        subHeader={subHeader}
      >
        {header}
      </HeaderBlock>

      <Markdown className='PcClass_description'>
        {generateTextLinks(descriptionMain)}
      </Markdown>

      <SourceInfo
        className='PcClass_source'
        source={source}
        useFullName
        themeColumn
      />
    </section>

    <Note
      note={note}
      className='PcClass_note'
    />

    <section className='PcClass_textList'>
      <DescriptionList
        {...rest}
        description={descriptionOtherList}
      />

      <Description {...proficiencyBonusTable} />
      <PcClassFeature pcClass={pcClass} />

      <Description
        header={`Развитие ${pcClassText}`}
        id={PROGRESS_TABLE_ID}
        text={progressTable}
        source={source}
        isOneColumn
        isFlexible
      />
      {
        spellSlotTable
          ? (
            <Description
              header={`Ячейки заклинаний ${pcClassText}`}
              id={SPELL_SLOT_TABLE_ID}
              text={spellSlotTable}
              source={source}
            />
          )
          : null
      }
    </section>

    <PcClassAbilityList
      abilityList={featureList}
      subHeaderGenerator={lvl => `Умение ${pcClassText} ${lvl} уровня`}
    />
    <PcClassImageList pcClassId={pcClassId}/>
  </section>
)

export default PcClassComponent
