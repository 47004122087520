const {PC_CLASS_PALADIN} = require('./../../pcClassIdList')
const {SOURCE_MTG_CLB} = require('./../../sourceList')

const {
  Dwarf_Paladin_Female_SCAG_data,
  Human_Paladin_seek_guidance_TCoE_data,
  Dragonborn_Paladin_Redclay_AYAGWW_data,
} = require('./../commonImageCollection')
const Paladin_MTG_CLB_img = require('./../../../images/pcClasses/paladin/paladin-mtg_clb.jpg')

module.exports = {
  [PC_CLASS_PALADIN]: [
    Dwarf_Paladin_Female_SCAG_data,
    Human_Paladin_seek_guidance_TCoE_data,
    Dragonborn_Paladin_Redclay_AYAGWW_data,
    {
      src: Paladin_MTG_CLB_img,
      text: 'Паладин',
      source: {
        id: SOURCE_MTG_CLB,
      },
    }
  ],
}
