const {PC_CLASS_ROGUE} = require('./../../pcClassIdList')

const {
  Drow_Rogue_TCoE_data,
  Dhampir_rogue_VRGtR_data,
  Halfling_Rogue_Female_SCAG_data,
  Halfling_Lightfoot_Female_Shandie_data,
  Tabaxi_AYAGWW_data,
  Kenku_Rogue_AYAGWW_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_CLASS_ROGUE]: [
    Drow_Rogue_TCoE_data,
    Dhampir_rogue_VRGtR_data,
    Halfling_Rogue_Female_SCAG_data,
    Halfling_Lightfoot_Female_Shandie_data,
    Tabaxi_AYAGWW_data,
    Kenku_Rogue_AYAGWW_data,
  ],
}
