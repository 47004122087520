import React from 'react'

import PcClass from '@/components/PcClass'
import PcClassList from '@/components/PcClassList'

import {pcClassCollection} from "@/constants/pcClassList"
import pcClassImageCollection from '@/constants/images/pcClassImageCollection'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Классы',
  description: 'Каталог классов Dungeons & Dragons',
  img: null,
}

const PcClassPageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={pcClassCollection}
    ItemComponent={PcClass}
    itemImageCollection={pcClassImageCollection}
    ListComponent={PcClassList}
    {...props}
  />
)

export default PcClassPageTemplate
