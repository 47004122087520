import React from 'react'

import { pcClassCollection } from '@/constants/pcClassList'
import {
  featureCollection,
  featureIdListByPcClassId,
} from '@/constants/featureList'

import generateNameStr from '@/utils/generateNameStr'
import upLetter from '@/utils/upLetter'
import splitDescription from '@/utils/splitDescription'

import PcClassComponent from './PcClassComponent'

export default ({ id: pcClassId }) => {
  if (pcClassId) {
    const pcClass = pcClassCollection[pcClassId]
    const featureList = featureIdListByPcClassId[pcClassId].map(
      id => featureCollection[id]
    )
    const {
      name: {
        singular: {
          nominative: name,
          genitive: pcClassText,
        },
      },
      nameEn,
      source,
      description,
      progressTable,
      spellSlotTable,
    } = pcClass

    const { header, subHeader } = generateNameStr({ name, nameEn })
    const [descriptionMain, descriptionOtherList] = splitDescription(description)

    return (
      <PcClassComponent
        featureList={featureList}
        header={upLetter(header)}
        subHeader={upLetter(subHeader)}
        source={source}
        descriptionMain={descriptionMain}
        descriptionOtherList={descriptionOtherList}
        pcClass={pcClass}
        pcClassId={pcClassId}
        pcClassText={pcClassText}
        progressTable={progressTable}
        spellSlotTable={spellSlotTable}
        {...pcClass}
      />
    )
  }

  return null
}
