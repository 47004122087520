import generateArmorText from './generateArmorText'
import generateLanguageText from './generateLanguageText'
import generateSavingThrowText from './generateSavingThrowText'
import generateSkillText from './generateSkillText'
import generateToolText from './generateToolText'
import generateWeaponText from './generateWeaponText'

const generateProficiencyInfo = ({ armor, weapon, tool, savingThrow, skill, language }, showEmpty) => {
  const NO_PROFICIENCY_TEXT = showEmpty
    ? 'нет'
    : ''

  const armorText = armor
    ? generateArmorText(armor)
    : NO_PROFICIENCY_TEXT
  const weaponText = weapon
    ? generateWeaponText(weapon)
    : NO_PROFICIENCY_TEXT
  const toolText = tool
    ? generateToolText(tool)
    : NO_PROFICIENCY_TEXT
  const savingThrowText = savingThrow
    ? generateSavingThrowText(savingThrow)
    : NO_PROFICIENCY_TEXT
  const skillText = skill
    ? generateSkillText(skill)
    : NO_PROFICIENCY_TEXT
  const languageText = language
    ? generateLanguageText(language)
    : NO_PROFICIENCY_TEXT

  return {
    armorText,
    languageText,
    savingThrowText,
    skillText,
    toolText,
    weaponText,
  }
}

export default generateProficiencyInfo
