const {PC_CLASS_RANGER} = require('./../../pcClassIdList')
const {SOURCE_AYAGWnW} = require('./../../sourceList')

const {
  Halfling_Ranger_TCoE_data,
  Ranger_Drizzt_and_Guenhwyvar_SCAG_data,
  Ranger_Rashemi_Minsk_AYAGWW_data,
} = require('./../commonImageCollection')
const Ranger_AYAGWW_img = require('./../../../images/races/ranger-AYAGWW.png')

module.exports = {
  [PC_CLASS_RANGER]: [
    Halfling_Ranger_TCoE_data,
    Ranger_Drizzt_and_Guenhwyvar_SCAG_data,
    Ranger_Rashemi_Minsk_AYAGWW_data,
    {
      src: Ranger_AYAGWW_img,
      text: 'Следопытка',
      source: {
        id: SOURCE_AYAGWnW,
      },
    },
  ],
}
