import React from 'react'
import arrify from 'arrify'

import joinLastItem from '@/utils/joinLastItem'

import { gearCategoryCollection } from '@/constants/gearCategoryList'

const generateArmorText = armor => joinLastItem(
  arrify(armor).reduce(
    (list, catId) => [
      ...list,
      gearCategoryCollection[catId].name,
    ],
    [],
  ),
)

export default generateArmorText
