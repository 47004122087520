const {PC_CLASS_DRUID} = require('./../../pcClassIdList')

const {
  Female_Druid_Wild_companion_TCoE_data,
  Gnome_Forest_Druid_Female_SCAG_data,
  Half_Elf_Female_Druid_SCAG_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_CLASS_DRUID]: [
    Gnome_Forest_Druid_Female_SCAG_data,
    Half_Elf_Female_Druid_SCAG_data,
    Female_Druid_Wild_companion_TCoE_data,
  ],
}
