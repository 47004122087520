import arrify from 'arrify'

import joinLastItem from '@/utils/joinLastItem'

import { paramCollection } from '@/constants/paramList'

const generateSavingThrowText = savingThrow => joinLastItem(
    arrify(savingThrow).reduce(
    (list, paramId) => [
      ...list,
      paramCollection[paramId].name,
    ],
    [],
  )
)

export default generateSavingThrowText
