import React from 'react'
import { Link } from 'gatsby'

import Markdown from '@/components/Markdown'
import Monet from '@/components/Monet'

import './PcEquipmentStyles.less'

const PcEquipmentComponent = (
  {
    coinsCountText,
    preText,
    showBuyVariant,
    text,
  }
) => (
  <div className='PcEquipment'>
    <section className='PcEquipment_normal'>
      <header className='PcEquipment_header'>Стартовое снаряжение</header>
      <p>{preText}</p>
      <Markdown className='PcEquipment_text'>{text}</Markdown>
    </section>
    {
      showBuyVariant
        ? (
          <section className='PcEquipment_coins'>
            <header className='PcEquipment_subHeader'>Покупка снаряжения</header>
            <p>Альтернативно Вы можете отказаться от вышеперечисленного стартового снаряжения и снаряжения за <Link to='/background-catalog'>предысторию</Link>. Вместо этого Вы начнёте игру с <b><Monet count={coinsCountText} /></b> для покупки <Link to='/gear-catalog'>снаряжения на Ваш выбор</Link>.</p>
          </section>
        )
        : null
    }
  </div>
)

export default PcEquipmentComponent
