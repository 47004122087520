import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'

import './PcSubClassListStyles.less'

const PcSubClassListComponent = ({ list }) => list && list.length
  ? (
    <ul className='PcSubClassList'>
      {
        list.map(
          (
            {
              className,
              pcClassId,
              text,
              url,
              source: {
                isUA,
                name: title,
                shortName: sourceText,
                url: sourceUrl,
              },
            },
            i
          ) => {
            const child = (
              <React.Fragment>
                {text}
                {
                  !url && sourceUrl
                    ? (
                      <a
                        className='PcSubClassList_source PcSubClassList_sourceLink'
                        title={title}
                        href={sourceUrl}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        {sourceText}
                      </a>
                    )
                    : (
                      <span
                        className='PcSubClassList_source'
                        title={title}
                      >
                        {sourceText}
                      </span>
                    )
                }
              </React.Fragment>
            )

            const classNameValue = classNames(
              'PcSubClassList_link',
              `PcSubClassList_link-${pcClassId}`,
              className,
              {
                'PcSubClassList_link-hasUrl': url,
                'PcSubClassList_link-noUrl': !url,
                'PcSubClassList_link-isUA': isUA,
                'PcSubClassList_link-isNotUA': !isUA,
              }
            )

            return (
              <li
                className='PcSubClassList_item'
                key={`item_${url}_${text}_${i}`}
              >
                {
                  url
                    ? (
                      <Link
                        to={url}
                        className={classNameValue}
                      >
                        {child}
                      </Link>
                    )
                    : (
                      <span className={classNameValue}>
                        {child}
                      </span>
                    )
                }
              </li>
            )
          },
        )
      }
    </ul>
  )
  : null

PcSubClassListComponent.propTypes = {
  list: PropTypes.array.isRequired,
}

PcSubClassListComponent.defaultProps = {
  list: [],
}

export default PcSubClassListComponent
