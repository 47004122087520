import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import { pcSubClassIdByClassIdCollection } from '@/constants/pcSubClassList'
import pcClassImageCollection from '@/constants/images/pcClassImageCollection'
import pcSubClassImageCollection from '@/constants/images/pcSubClassImageCollection'
import {pcClassCollection} from '@/constants/pcClassList'

const PcClassImageListContainer = ({ pcClassId }) => {
  if (pcClassId) {
    const pcClass = pcClassCollection[pcClassId]
    const imageClassList = pcClassImageCollection[pcClassId] || []
    const pcSubClassIdList = pcSubClassIdByClassIdCollection[pcClassId] || []
    const imageSubClassList = pcSubClassIdList.reduce(
      (list, pcSubClassId) => {
        const imageList = pcSubClassImageCollection[pcSubClassId]

        return imageList && imageList.length
          ? [
            ...list,
            ...imageList,
          ]
          : list
      },
      []
    )
    const imageList = [
        ...imageClassList,
        ...imageSubClassList,
    ]

    if (imageList) {
      return (
        <ImageList
          imageList={imageList}
          {...pcClass}
        />
      )
    }

    return null
  }

  return null
}

PcClassImageListContainer.propTypes = {
  pcClassId: PropTypes.string.isRequired,
}

export default PcClassImageListContainer
