const {PC_CLASS_FIGHTER} = require('./../../pcClassIdList')

const {
  Human_Tiefling_Sparring_TCoE_data,
  Fighter_Tiefling_SCAG_data,
  Fighter_AYAGWW_data,
  Fighter_Dwarf_Bruenor_AYAGWW_data,
  Dwarf_Fighter_MGZN_DRGN_378_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_CLASS_FIGHTER]: [
    Fighter_Tiefling_SCAG_data,
    Human_Tiefling_Sparring_TCoE_data,
    Fighter_AYAGWW_data,
    Fighter_Dwarf_Bruenor_AYAGWW_data,
    Dwarf_Fighter_MGZN_DRGN_378_data,
  ],
}
