import React from 'react'
import arrify from 'arrify'

import generatePcSubClassPageUrlById from '@/utils/generatePcSubClassPageUrlById'
import sortByText from '@/utils/sortByText'

import { pcSubClassIdByClassIdCollection, pcSubClassCollection } from '@/constants/pcSubClassList'
import { sourceCollection } from '@/constants/sourceList'

const generatePcSubClassList = (pcClassId) => pcSubClassIdByClassIdCollection[pcClassId]
  .reduce(
    (list, subClassId) => {
      const {name, isReady, isReReleased, source: sourceRaw} = pcSubClassCollection[subClassId]
      if (isReReleased) {
        return list
      } else {
        const {id: sourceId, url: sourceUrl} = arrify(sourceRaw)[0]
        const source = sourceUrl
          ? {
            ...sourceCollection[sourceId],
            url: sourceUrl,
          }
          : sourceCollection[sourceId]

        return [
          ...list,
          {
            className: `${pcClassId} ${subClassId}`,
            text: name,
            pcClassId,
            source,
            url: isReady
              ? generatePcSubClassPageUrlById(subClassId, pcClassId)
              : null,
          },
        ]
      }
    },
    []
  )
  .sort(sortByText)

export default generatePcSubClassList
