module.exports = {
  ...require('./pcClassImageCollection_barbarian'),
  ...require('./pcClassImageCollection_bard'),
  ...require('./pcClassImageCollection_cleric'),
  ...require('./pcClassImageCollection_druid'),
  ...require('./pcClassImageCollection_fighter'),
  ...require('./pcClassImageCollection_monk'),
  ...require('./pcClassImageCollection_paladin'),
  ...require('./pcClassImageCollection_ranger'),
  ...require('./pcClassImageCollection_rogue'),
  ...require('./pcClassImageCollection_sorcerer'),
  ...require('./pcClassImageCollection_warlock'),
  ...require('./pcClassImageCollection_wizard'),
}
