const {PC_CLASS_MONK} = require('./../../pcClassIdList')

const {
  Halfling_Stout_Monk_AYAGWW_data,
  Human_Monk_Male_SCAG_data,
  Kenku_Monk_Whey_Shu_AYAGWW_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_CLASS_MONK]: [
    Human_Monk_Male_SCAG_data,
    Kenku_Monk_Whey_Shu_AYAGWW_data,
    Halfling_Stout_Monk_AYAGWW_data,
  ],
}
