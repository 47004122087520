import arrify from 'arrify'
import declint from 'declint-ru'

import joinLastItem from '@/utils/joinLastItem'

import { gearCategoryCollection } from '@/constants/gearCategoryList'
import { gearCollection } from '@/constants/gearList'

const generateToolText = ({toChoose, list, comment}) => {
  let result = ''

  if (list && list.length) {
    const toolNameText = joinLastItem(
      arrify(list)
        .map(
          (itemId) => {
            const gear = gearCollection[itemId]
            const category = gearCategoryCollection[itemId]
            const item = gear || category
            const isCategory = Boolean(category)

            if (!item) {
                return null
            }

            const { name } = item
            return isCategory
              ? name
              : `[${name}](GEAR:${itemId})`
          },
        )
        .filter(e => e)
        .sort(
          (A, B) => A > B ? 1 : -1,
        )
    )

    result += `${toolNameText}. `
  }

  if (toChoose && toChoose.count) {
    const {
      collection,
      listToHandle,
      isGear,
    } = (
      toChoose.list
      && !toChoose.limitCatList
    )
      ? {
        collection: gearCollection,
        isGear: true,
        listToHandle: arrify(toChoose.list),
      }
      : {
        collection: gearCategoryCollection,
        isGear: false,
        listToHandle: arrify(toChoose.limitCatList),
      }

    const countText = declint(
      toChoose.count,
      listToHandle && listToHandle.length
        ? ['инструмент', 'инструмента', 'инструменты']
        : ['любой инструмент', 'любых инструмента', 'любых инструменты']
    )

    const toolNameText = listToHandle && listToHandle.length
      ? joinLastItem(
        listToHandle
          .reduce(
            (list, id) => [
              ...list,
              isGear
                ? `[${collection[id].name}](GEAR:${id})`
                : collection[id].name,
            ],
            [],
          )
          .sort(
            (A, B) => A > B ? 1 : -1,
          ),
        undefined,
        listToHandle.length > 1
          ? ' или '
          : undefined
        )
      : ''

    result += toolNameText
      ? toChoose.count > 1
        ? `Выберите ${toChoose.count} ${countText} ${toolNameText}`
        : `Выберите ${toChoose.count} ${countText} из: ${toolNameText}`
      : `Выберите ${toChoose.count} ${countText}`
  }

  const commentText = comment
    ? ` (${comment})`
    : ''

  return `${result}${commentText}`
}

export default generateToolText
