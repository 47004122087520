const {PC_CLASS_WIZARD} = require('./../../pcClassIdList')

const {
  SOURCE_MTG_CLB,
} = require('./../../sourceList')

const {
  Warforged_Wizard_WGtE_data,
  Wizard_Students_TCoE_data,
  Wizard_Sun_Elf_Female_SCAG_data,
  Gnome_Rock_Wizard_Male_DMG_data,
  Tiefling_wizard_MTG_AFR_data,
  Orc_feywild_caretaker_MTG_CLB_data,
} = require('./../commonImageCollection')

const Gale_MTG_CLB_img = require('./../../../images/pcClasses/wizard/gale-mtg_cls.jpg')

module.exports = {
  [PC_CLASS_WIZARD]: [
    Wizard_Sun_Elf_Female_SCAG_data,
    Wizard_Students_TCoE_data,
    Gnome_Rock_Wizard_Male_DMG_data,
    Warforged_Wizard_WGtE_data,
    Tiefling_wizard_MTG_AFR_data,
    Orc_feywild_caretaker_MTG_CLB_data,
    {
      src: Gale_MTG_CLB_img,
      text: 'Гейл, гений из Глубоководья',
      source: {
        id: SOURCE_MTG_CLB,
      },
    }
  ],
}
