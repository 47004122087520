import React from 'react'

import PcProficiency from '@/components/PcProficiency'
import PcEquipment from '@/components/PcEquipment'

import PcClassHitInfo from './components/PcClassHitInfo'

import './PcClassFeatureStyles.less'

export default ({ pcClass, featureCollection: {equipment, proficiency} }) => (
  <>
    <PcClassHitInfo pcClass={pcClass} />
    <PcProficiency proficiency={proficiency} />
    <PcEquipment equipment={equipment}/>
  </>
)
