import React from 'react'

import generateTextLinks from '@/utils/generateTextLinks'

import PcEquipmentComponent from './PcEquipmentComponent'

const PcEquipmentContainer = (
  {
    equipment,
    preText = 'Вы начинаете со следующим снаряжением в дополнение к снаряжению, полученному за предысторию',
    showBuyVariant = true,
  }
) => {
  const {text, coins} = typeof equipment === 'object'
    ? equipment
    : {text: equipment}

  const multiplyText = coins && coins.times
    ? ` × ${coins.times}`
    : ''

  const coinsCountText = typeof coins === 'object'
    ? `${coins.diceCount}к${coins.diceType}${multiplyText}`
    : coins

  return (
    <PcEquipmentComponent
      coinsCountText={coinsCountText}
      text={generateTextLinks(text)}
      preText={preText}
      showBuyVariant={showBuyVariant}
    />
  )
}

export default PcEquipmentContainer
