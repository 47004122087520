import arrify from 'arrify'

import joinLastItem from '@/utils/joinLastItem'

import { gearCategoryCollection } from '@/constants/gearCategoryList'
import { gearCollection } from '@/constants/gearList'

const generateWeaponText = weapon => {
  let result = []

  if (weapon.catList) {
    result = result.concat(
      arrify(weapon.catList).reduce(
        (list, catId) => [
          ...list,
          gearCategoryCollection[catId].name,
        ],
        [],
      )
    )
  }

  if (weapon.gearList) {
    result = result.concat(
      arrify(weapon.gearList).reduce(
        (list, gearId) => [
          ...list,
          `[${gearCollection[gearId].name}](GEAR:${gearId})`,
        ],
        [],
      )
    )
  }

  return joinLastItem(result)
}

export default generateWeaponText
