import calcDiceAverage from '@/utils/calcDiceAverage'

const generateHitInfo = (hitDice, pcClassNameText) => {
  const hitDiceMedium = calcDiceAverage({diceBonus: 1, diceCount: 1, diceType: hitDice})
  const hitDiceText = `1к${hitDice} за каждый уровень ${pcClassNameText}`
  const hitFirstLvlText = `${hitDice} + модификатор Телосложения`
  const hitNextLvlText = `1к${hitDice} (или ${hitDiceMedium}) + модификатор Телосложения за каждый уровень ${pcClassNameText} после первого`

  return {
    hitDiceText,
    hitFirstLvlText,
    hitNextLvlText,
  }
}

export default generateHitInfo
