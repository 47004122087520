import React from 'react'

import './PcClassHitInfoStyles.less'

export default ({ hitInfo: { hitDiceText, hitFirstLvlText, hitNextLvlText } }) => (
  <section className='PcClassHitInfo'>
    <header className='PcClassHitInfo_header'>Хиты</header>
    <p className='PcClassHitInfo_diceText'>
      <b>Кость хитов:</b> {hitDiceText}
    </p>
    <p className='PcClassHitInfo_firstLvlText'>
      <b>Хиты на 1 уровне:</b> {hitFirstLvlText}
    </p>
    <p className='PcClassHitInfo_nextLvlText'>
      <b>Хиты на следующих уровнях:</b> {hitNextLvlText}
    </p>
  </section>
)
