import arrify from 'arrify'
import declint from 'declint-ru'

import joinLastItem from '@/utils/joinLastItem'
import generateLanguagePageUrlById from '@/utils/generateLanguagePageUrlById'

import { languageCollection } from '@/constants/languageList'

const DEFAULT_COUNT = 1

const generateLanguageText = (list) => {
  const languageList = arrify(list)

  if (languageList) {
    let countTotal = 0

    const languageText = joinLastItem(arrify(languageList).map(
      (language) => joinLastItem(
        arrify(language)
          .map(
            lang => {
              const {id: languageId, count = DEFAULT_COUNT, comment} = typeof lang === 'object'
                ? lang
                : {id: lang, count: DEFAULT_COUNT}
              const {name5eOfficial: name, isReady} = languageCollection[languageId]

              countTotal += count

              const { nominative } = (
                typeof name === 'function'
                  ? name(count)
                  : name
              )

              const commentText = comment
                ? ` (${comment})`
                : ''

              const nameText = isReady
                ? `[${nominative}](${generateLanguagePageUrlById(languageId)})`
                : nominative

              return `${nameText}${commentText}`
            }
          )
          .sort(
            (A, B) => A > B ? 1 : -1,
          ),
        ', ',
        ' или '
      ))
    )

    const countText = declint(
      countTotal,
      [
        'дополнительным языком',
        'дополнительными языками',
        'дополнительными языками',
      ]
    )

    return `Вы владеете ${countText}: ${languageText}`
  } else {
    return ''
  }
}

export default generateLanguageText
