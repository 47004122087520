const {PC_CLASS_CLERIC} = require('./../../pcClassIdList')

const {
  Female_Cleric_hardness_TCoE_data,
  Human_Cleric_Oghma_female_PHB_data,
  Cleric_of_Lathander_Human_Male_SCAG_data,
  Dawnbringer_cleric_MTG_CLB_data,
  Sune_s_intervention_MTG_AHBG_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_CLASS_CLERIC]: [
    Female_Cleric_hardness_TCoE_data,
    Human_Cleric_Oghma_female_PHB_data,
    Cleric_of_Lathander_Human_Male_SCAG_data,
    Dawnbringer_cleric_MTG_CLB_data,
    Sune_s_intervention_MTG_AHBG_data,
  ],
}
