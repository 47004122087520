const {SOURCE_MTG_CLB} = require('./../../sourceList')
const {PC_CLASS_WARLOCK} = require('./../../pcClassIdList')

const {
  Tiefling_Warlock_talisman_TCoE_data,
} = require('./../commonImageCollection')

const Warlock_pact_weapon_MTG_CLB_img = require('./../../../images/pcClasses/warlock/warlock_pact_weapon.jpg')

module.exports = {
  [PC_CLASS_WARLOCK]: [
    Tiefling_Warlock_talisman_TCoE_data,
    {
      src: Warlock_pact_weapon_MTG_CLB_img,
      text: 'Оружие договора',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
}
