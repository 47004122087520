const {PC_CLASS_BARD} = require('./../../pcClassIdList')

const {
  Green_Dragon_meets_Bard_DMG_data,
  Human_Female_Bard_SCAG_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_CLASS_BARD]: [
    Human_Female_Bard_SCAG_data,
    Green_Dragon_meets_Bard_DMG_data,
  ],
}
