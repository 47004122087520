import React from 'react'

import Markdown from '@/components/Markdown'

import generateTextLinks from '@/utils/generateTextLinks'

import './PcProficiencyStyles.less'

const PcProficiencyComponent = (
  {
    armorText,
    languageText,
    savingThrowText,
    showEmpty,
    skillText,
    toolText,
    weaponText,
  },
) => (
  <section className='PcProficiency'>
    <header className='PcProficiency_header'>Владения</header>
    {
      (showEmpty ? true : armorText)
        ? (
          <p className='PcProficiency_armorText'>
            <Markdown>
              {`**Доспехи:** ${generateTextLinks(armorText)}`}
            </Markdown>
          </p>
        )
        : null
    }
    {
      (showEmpty ? true : weaponText)
        ? (
          <p className='PcProficiency_weaponText'>
            <Markdown>
              {`**Оружие:** ${generateTextLinks(weaponText)}`}
            </Markdown>
          </p>
        )
        : null
    }
    {
      (showEmpty ? true : toolText)
        ? (
          <p className='PcProficiency_toolText'>
            <Markdown>
              {`**Инструменты:** ${generateTextLinks(toolText)}`}
            </Markdown>
          </p>
        )
        : null
    }
    {
      (showEmpty ? true : savingThrowText)
        ? (
          <p className='PcProficiency_savingThrowText'>
            <Markdown>
              {`**Испытания:** ${generateTextLinks(savingThrowText)}`}
            </Markdown>
          </p>
        )
        : null
    }
    {
      (showEmpty ? true : skillText)
        ? (
          <p className='PcProficiency_skillText'>
            <Markdown>
              {`**Навыки:** ${generateTextLinks(skillText)}`}
            </Markdown>
          </p>
        )
        : null
    }
    {
      (showEmpty ? true : languageText)
        ? (
          <p className='PcProficiency_languageText'>
            <Markdown>
              {`**Языки:** ${generateTextLinks(languageText)}`}
            </Markdown>
          </p>
        )
        : null
    }
  </section>
)

export default PcProficiencyComponent
