import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Icon from '@/components/Icon'

import './SubpageListStyles.less'

const SubpageListComponent = (
  {
    ChildListRenderer,
    className,
    linkClassName,
    list,
    themeCentred,
    themeSmall,
  }
) => (
  <ul className={classNames(
    'SubpageList',
    className,
    {
      'SubpageList-small': themeSmall,
      'SubpageList-centred': themeCentred,
    },
  )}>
    {
      list.map(
        ({ title, url, icon, id = '', childList }) => (
          <li
            key={url}
            className='SubpageList_item'
          >
            <Link
              to={url}
              className={`SubpageList_link ${linkClassName} ${id}`}
            >
              {
                icon
                  ? (
                    <Icon
                      icon={icon}
                      className='SubpageList_icon'
                    />
                  )
                  : null
              }
              <span className='SubpageList_text'>{title}</span>
            </Link>
            {
              ChildListRenderer && (
                <ChildListRenderer list={childList}/>
              )
            }
          </li>
        ),
      )
    }
  </ul>
)

SubpageListComponent.propTypes = {
  ChildListRenderer: PropTypes.func,
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  list: PropTypes.array.isRequired,
  themeCentred: PropTypes.bool,
  themeSmall: PropTypes.bool,
}

SubpageListComponent.defaultProps = {
  ChildListRenderer: null,
  className: '',
  linkClassName: '',
  themeCentred: false,
  themeSmall: false,
}

export default SubpageListComponent
