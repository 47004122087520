import React from 'react'

import generateProficiencyInfo from './utils/generateProficiencyInfo'

import PcProficiencyComponent from './PcProficiencyComponent'

const PcProficiencyContainer = ({ proficiency, showEmpty = true }) => {
  if (proficiency) {
    const {
      armorText,
      languageText,
      savingThrowText,
      skillText,
      toolText,
      weaponText,
    } = generateProficiencyInfo(proficiency, showEmpty)

    return (
      <PcProficiencyComponent
        armorText={armorText}
        languageText={languageText}
        savingThrowText={savingThrowText}
        showEmpty={showEmpty}
        skillText={skillText}
        toolText={toolText}
        weaponText={weaponText}
      />
    )
  }

  return null
}

export default PcProficiencyContainer
