const {PC_CLASS_SORCERER} = require('./../../pcClassIdList')

const {
  Dragonborn_Sorcerer_TCoE_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_CLASS_SORCERER]: [
    Dragonborn_Sorcerer_TCoE_data,
  ],
}
