import React from 'react'

import PageTitle from "@/components/PageTitle"
import SubpageList from "@/components/SubpageList"

import PcSubClassList from "./components/PcSubClassList"

import './PcClassListStyles.less'

export default ({ list }) => (
  <section className='PcClassList'>
    <PageTitle>Каталог классов персонажей</PageTitle>

    <SubpageList
      linkClassName='PcClassList_link'
      list={list}
      ChildListRenderer={PcSubClassList}
      themeSmall
      themeCentred
    />
  </section>
)
