import React from 'react'

import generateHitInfo from './utils/generateHitInfo'

import PcClassHitInfoComponent from './PcClassHitInfoComponent'

export default ({ pcClass, pcClass: { featureCollection: { hitDice } } }) => {
  if (pcClass && hitDice) {
    const { name: { singular: { genitive: pcClassNameText } } } = pcClass
    const hitInfo = generateHitInfo(hitDice, pcClassNameText)

    return (
      <PcClassHitInfoComponent
        hitInfo={hitInfo}
        pcClassNameText={pcClassNameText}
      />
    )
  }

  return null
}
