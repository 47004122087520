import React from 'react'

import PcClassFeatureComponent from './PcClassFeatureComponent'

export default ({ pcClass }) => {
  if (pcClass.featureCollection) {
    return (
      <PcClassFeatureComponent
        pcClass={pcClass}
        featureCollection={pcClass.featureCollection}
      />
    )
  }

  return null
}
