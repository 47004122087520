import arrify from 'arrify'
import declint from 'declint-ru'

import joinLastItem from '@/utils/joinLastItem'

import { skillCollection } from '@/constants/skillList'

const generateText = (list) => {
  if (list && list.length) {
    const skillChosenTextList = arrify(list)
      .reduce(
        (savedList, skill) => [
          ...savedList,
          skillCollection[skill].name,
        ],
        [],
      )
      .sort(
        (A, B) => A > B ? 1 : -1,
      )

    return joinLastItem(skillChosenTextList)
  } else {
    return ''
  }
}

const generateSkillText = ({toChoose, list, comment = ''}) => {
  const textList = []

  if (list && list.length) {
    const skillNameText = generateText(list)
    const countText = declint(list.length, ['навыком', 'навыками:', 'навыками:'])

    textList.push(`Вы владеете ${countText} ${skillNameText}.`)
  }

  if (toChoose) {
    const {list: toChooseList, count = 1} = toChoose
    const skillNameText = generateText(toChooseList)

    if (skillNameText) {
      const countText = declint(count, ['навык', 'навыка', 'навыков'])

      textList.push(`Выберите ${count} ${countText} из следующих: ${skillNameText}.`)
    } else {
      const countText = declint(count, ['любой навык', 'любых навыка', 'любых навыков'])

      textList.push(`Выберите ${count} ${countText}.`)
    }
  }

  if (comment) {
    textList.push(comment)
  }

  return textList.join(' ')
}

export default generateSkillText
