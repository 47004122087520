const {PC_CLASS_BARBARIAN} = require('./../../pcClassIdList')

const {
  Human_Female_Barbarian_SCAG_data,
  Uthgardt_tribe_member_SCAG_data,
  Barbarian_Wulfgar_AYAGWW_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_CLASS_BARBARIAN]: [
    Human_Female_Barbarian_SCAG_data,
    Uthgardt_tribe_member_SCAG_data,
    Barbarian_Wulfgar_AYAGWW_data,
  ],
}
