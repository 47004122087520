module.exports = {
  header: 'Бонус мастерства',
  subHeader: 'Proficiency bonus',
  text: `
| Уровень | Бонус | Уровень | Бонус |
|---------|-------|---------|-------|
|  1      | +2    | 11      | +4    |
|  2      | +2    | 12      | +4    |
|  3      | +2    | 13      | +5    |
|  4      | +2    | 14      | +5    |
|  5      | +3    | 15      | +5    |
|  6      | +3    | 16      | +5    |
|  7      | +3    | 17      | +6    |
|  8      | +3    | 18      | +6    |
|  9      | +4    | 19      | +6    |
| 10      | +4    | 20      | +6    |
`,
}
